import React from "react";
import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import Section from "../components/section";




const ToolsSection = (props) => {
  return (
    <Section className="bg-very-lightest-grey  border-solid border border-very-grey-light">
      <div className="w-full px-4 py-8 md:py-24 text-center flex flex-col ">
        <div className="flex flex-wrap justify-between">
          <div>
            <Img fixed={props.data.python.childImageSharp.fixed} alt="" />
          </div>
          <div>
            <Img fixed={props.data.scrapy.childImageSharp.fixed} alt="" />
          </div>
          <div>
            <Img fixed={props.data.scikit.childImageSharp.fixed} alt="" />
          </div>
          <div>
            <Img fixed={props.data.neo4j.childImageSharp.fixed} alt="" />
          </div>
          <div>
            <Img fixed={props.data.tensorflow.childImageSharp.fixed} alt="" />
          </div>
        </div>
      </div>
    </Section>
  )
}


export default ToolsSection;

export const pageQuery = graphql`
query {
    python: file(relativePath: {eq: "images/python-logo.png" }) {
      childImageSharp {
        fixed(width: 280, height: 100) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    scikit: file(relativePath: {eq: "images/scikit-learn-logo.png" }) {
      childImageSharp {
        fixed(width: 180, height: 100) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    scrapy: file(relativePath: {eq: "images/scrapy-logo.png" }) {
      childImageSharp {
        fixed(width: 280, height: 100) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    neo4j: file(relativePath: {eq: "images/neo4j-logo.png" }) {
      childImageSharp {
        fixed(width: 250, height: 100) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    tensorflow: file(relativePath: {eq: "images/tensorflow-logo.webp" }) {
      childImageSharp {
        fixed(width: 320, height: 60) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
  }
`

